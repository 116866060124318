var languageConfigs = {
  asset: {
    assetName: "Tên vật tư / thiết bị",
    assetCode: "Mã sản phẩm",
    description: "Mô tả cách dùng",
    type: "Phân loại",
    amount: "Số lượng trong kho",
    price: "Giá mỗi đơn vị",
    asset: "Thiết bị cố định",
    consumable: "Vật tư tiêu hao",
    advancesetting: "Thiết lập nâng cao",
    instock: "Kho",
    create: "Tạo mới vật tư / thiết bị",
    createAssetConfirmMessage:
      "Sau khi tạo mới bạn sẽ không thể thay đổi phân loại vật tư. Bạn có muốn tiếp tục?",
    delete: "Xóa vật tư / thiết bị",
    deleteAssetConfirmMessage:
      "Xóa không thể phục hồi được. Bạn có muốn tiếp tục?",
    showQr: "Hiện QR code",
    hideQr: "Ẩn QR code",
    importInventory: "Điều chỉnh SL trong kho",
    amountChange: "Số lượng tăng/giảm",
    reasonChange: "Lý do điều chỉnh",
    consumeRequest: "Đơn sử dụng",
    consumeAmmount: "Số lượng",
    consumeDescription: "Ghi chú",
    consumePrice: "Chi phí sử dụng",
    consumePriceEach: "Giá bán mỗi đơn vị",
    consumeAmmountShort: "SL",
    addItem: "Chọn",
    confirm: "Xác nhận",
    confirmMessage: "Bạn có chắc muốn thực hiện điều này?",
  },
  user: {
    profile: "Thông tin cá nhân",
    setting: "Cài đặt",
    logout: "Đăng xuất",
    phone: "Số điện thoại",
    fullName: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    email: "Email",
    address: "Địa chỉ",
    occupation: "Nghề nghiệp",
    country: "Quốc gia",
    call: "Gọi",
    password: "Mật khẩu",
    profileUrl: "Mã thành viên",
  },
  booking: {
    time: "Giờ đặt lịch",
    price: "Chi phí phát sinh (nếu có)",
    complaint: "Nội dung đặt lịch",
    note: "Ghi chú thêm",
    myappointment: "Đặt lịch của tôi",
  },
  episode: {
    myepisode: "Báo cáo của tôi",
    exam: "Nội dung báo cáo",
    info: "Thông tin báo cáo",
    checkintime: "Thời điểm bắt đầu",
    clinicaldatanotavailable: "Nội dung báo cáo chưa được cập nhật",
    success: "Báo cáo hoàn thành",
    canceled: "Báo cáo bị hủy",
  },
  clinic: {
    name: "Tên phòng lab",
    description: "Mô tả",
    tax: "Mã số cơ quan (nếu có)",
    hotline: "Số hotline",
  },
  service: {
    name: "Tên bài báo cáo",
    price: "Dự tính kinh phí (nếu có)",
    description: "Mô tả báo cáo nghiên cứu",
    noproviders: "Chưa có GV hướng dẫn",
  },
  home: {
    welcome: "Chào mừng",
    haveaniceday: "Chúc bạn một ngày tốt lành!",
    report: "Thống kê",
    event: "Đặt lịch",
    episode: "Báo cáo",
    notification: "Hoạt động",
  },
  auth: {
    or: "Hoặc",
    emailorphone: "Số điện thoại",
    login: "Đăng nhập",
    signup: "Đăng ký",
    verifyphone: "Vui lòng nhập số điện thoại",
    sendcode: "Gửi mã",
    alreadyhaveaccount: "Bạn đã có tài khoản?",
    donthaveaccount: "Bạn chưa có tài khoản?",
  },
  register: {
    editlogo: "Chọn logo",
    verifyCode: "Nhập mã xác thực",
    becomedoctor: "Đăng ký thành viên nghiên cứu",
    registerclinic: "Đăng ký tổ chức nghiên cứu",
    registeraccount: "Đăng ký đối tác nghiên cứu",
    register: "Đăng ký",
    continue: "Tiếp tục",
    additionalinfo: "Ngày sinh & Giới tính",
    male: "Nam",
    female: "Nữ",
    completed: "Hoàn thành",
    startusingapp: "Bắt đầu sử dụng",
    message1:
      "Thông tin này được sử dụng nhằm cung cấp trải nghiệm tốt hơn cho người dùng.",
    message2: "Cảm ơn bạn đã đăng ký tài khoản!",
    message3:
      "Chúng tôi sẽ liên hệ bạn trong vòng 24h để kiểm chứng thông tin. Bạn đã có thể sử dụng Sandrasoft từ lúc này. Xin cảm ơn.",
  },
  common: {
    galery: "Thư viện ảnh",
    upload: "Tải lên",
    sale: "Bán",
    forSale: "Đăng bán",
    saleHighlight: "Trích đoạn ngắn",
    category: "Chủ đề",
    back: "Quay lại",
    isSaleOrder: "Chọn làm biểu mẫu đơn bán hàng",
    saleOrder: "Biểu mẫu đơn bán hàng",
    totalPrice: "Tổng giá trị",
    search: "Tìm kiếm",
    checkin: "Viết báo cáo",
    appoint: "Đặt lịch",
    choose: "Chọn",
    selecttoview: "Chọn thẻ để xem",
    selectdatetime: "Chọn ngày & giờ",
    create: "Tạo mới",
    info: "Thông tin",
    detail: "Thông tin chi tiết",
    select: "Chọn",
    datetime: "Ngày & giờ",
    confirm: "Chọn",
    confirmed: "Xác nhận",
    delete: "Hủy",
    cancel: "Hủy",
    save: "Lưu",
    finish: "Hoàn thành",
    selectfile: "Chọn tệp",
    today: "Hôm nay",
    at: "Lúc",
    invite: "Thêm",
    user: "Thành viên",
    notprovide: "Chưa có dữ liệu",
    nodata: "Chưa có dữ liệu",
    awesomenodata: "Bạn chưa có thông báo mới.",
    lastvisits: "Báo cáo gần đây",
    verifyUpdate: "Đang khởi chạy ứng dụng, xin vui lòng đợi trong giây lát...",
    serverunavailable: "Server đang được cập nhật. Vui lòng quay lại sau.",
    backtohome: "Về trang chủ",
    timeStart: "Giờ bắt đầu",
    timeEnd: "Giờ kết thúc",
    examcontent: "Nội dung báo cáo",
    clinicaldata: "Ghi chú",
    account: "Tài khoản",
    report: "Báo cáo",
    month: "Tháng",
    rebooking: "Hẹn lần nữa",
    open: "Mở trang",
    title: "Tiêu đề",
    headline: "Mô tả ngắn",
    share: "Chia sẻ",
    unshare: "Ngừng chia sẻ",
    confirmShare:
      "Chia sẻ thông tin này cho người khác xem. Bạn có chắc muốn tiếp tục?",
    confirmUnshare:
      "Khi ngừng chia sẻ người khác sẽ không còn xem được thông tin này. Bạn có chắc muốn tiếp tục?",
    change: "Đổi",
    oldPassword: "Mật khẩu cũ",
    newPassword: "Mật khẩu mới",
    retypePassword: "Nhập lại mật khẩu mới",
    changePasswordFailMessage: "Thay mật khẩu không thành công",
    changePasswordSuccessMessage:
      "Thay mật khẩu thành công. Vui lòng đăng nhập lại",
  },
  nav: {
    home: "Trang chủ",
    patient: "Thành viên",
    doctor: "GV hướng dẫn",
    booking: "Đặt lịch",
    episode: "Báo cáo",
    search: "Tìm kiếm",
    setting: "Cài đặt",
    clinic: "Phòng lab",
    service: "Đề mục",
  },
  cardTitle: {
    clinic: "Phòng lab của tôi",
    clinics: "Các phòng lab",
    patients: "Danh sách thành viên",
    bookings: "Danh sách đặt lịch",
    episodes: "Danh sách báo cáo",
    doctors: "Danh sách thành viên",
    services: "Danh sách biểu mẫu",
    assets: "Kho vật tư tiêu hao",
  },
  error: {
    phonealreadyinuse:
      "Số điện thoại này đã được sử dụng. Xin vui lòng dùng số khác.",
    verifycodefailed: "Không thể gửi code đến số điện thoại này.",
    retrysendcode: "Thử lại với số khác",
  },
};
export default languageConfigs;
